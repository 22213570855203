import { useRef, useEffect, useState, useMemo } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { post, authHeader } from '../service/fetch';
import config from '../config';

const initialOptions = {
    autoplay: false,
    sources: '', 
    height: '355px'
};

const getUrlByVideoId = (videoId) => `${config.video.host}/videos/${videoId}`;

const getNameByVideoId = (videoId) => videoId.replaceAll(".mp4", "");

const VideoPlayer = () => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const [selectedVideoId, setSelectedVideoId] = useState();
    const [videoIdList, setVideoIdList] = useState();

    const videoOptions = useMemo(() => {
        return {
            ...initialOptions,
            sources: getUrlByVideoId(selectedVideoId),
        };
    }, [selectedVideoId]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await post({
                    url: `${config.chatbotApi.host}:${config.chatbotApi.port}/videoList`,
                    headers: authHeader(),
                });

                const { latest, videos } = response;
                const sortedVideos = videos.sort((a, b) => b.localeCompare(a));

                setSelectedVideoId(latest);
                setVideoIdList(sortedVideos);
            } catch (e) {
                console.error('Error fetching video list:', e);
            }
        };

        fetchVideos();
    }, []); // Run only once when the component mounts

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = document.createElement("video-js");
            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            // Initialize video.js player
            const player = videojs(videoElement, videoOptions, () => {
                videojs.log('player is ready');
            });

            playerRef.current = player;
        } else {
            const player = playerRef.current;
            if (videoOptions.sources) {
                player.src(videoOptions.sources); // Update the player source
            }
        }

        // Cleanup player on unmount or when videoOptions change
        return () => {
            const player = playerRef.current;
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [videoOptions]);

    return (
        <div className="video-player__container">
            <div className="video-player__canvas" data-vjs-player>
                <video
                    className={"video-js vjs-fill"}
                    ref={videoRef}
                    src={videoOptions.sources}
                    controls
                    width="620"
                    style={{ display: "block", margin: "0 auto" }}
                />
            </div>

            <div className="video-player__history">
                <div className="video-player__history--title">
                Daily News Videos
                </div>
                {videoIdList !== undefined ? <HistoryVideoList
                    videoIdList={videoIdList.filter(videoId => videoId !== selectedVideoId)} onVideoIdChange={videoId => setSelectedVideoId(videoId)}/>
                    : "Loading..."}
            </div>
        </div>
    );
};

const HistoryVideoList = ({ videoIdList, onVideoIdChange }) => {
    const videoRef = useRef(null);

    return <div className={"video-player__history--list"}>
        {videoIdList.map((videoId, index) => <div key={index} className={"video-player__history--item"} onClick={() => onVideoIdChange(videoId)}>
            <div className={"video-player__history--cover"}>
                <div className={"video-player__history--mask"} />
                <video
                    ref={videoRef}
                    src={getUrlByVideoId(videoId)}
                    width={"130"}
                    height={"75"}
                    style={{display: "block", margin: "0 auto"}}
                />
            </div>
            <div className={"video-player__history--description"}>
                {getNameByVideoId(videoId)}
            </div>
        </div>)}
    </div>
}

export default VideoPlayer;