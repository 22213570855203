const config = {
  fe: {
    host: 'https://www.alphacrypto.tech',
    //host: 'http://13.213.10.142',
    port: 443,
    //port: 16161,
  },
  chatbotApi: {
    host: 'https://chatbot-api.alphacrypto.tech',
    //host: 'http://13.213.10.142',
    port: 443
    //port: 16161,
  },
  chartApi: {
    host: 'https://chart-api.alphacrypto.tech',
    //host: 'http://13.213.10.142',
    //port: 5000
    //host: 'http://13.229.153.184',
    port: 443
    //port: 16161
  },
  video: {

    host: 'https://video-api.alphacrypto.tech',

    port: 11980,
  }
}

export default config;
